<!--
 * @Autor: 符应飞
 * @Date: 2021-10-30 11:29:54
 * @LastEditors: 符应飞
 * @Description: 人脸登录 修改了图片镜像
 * @LastEditTime: 2021-10-30 11:29:54
-->
<template>
  <div>
    <div 
    class="video"   
    :class="device=='ios'? 'rotateParent' : ''"
    v-if="!isAlreadyRecord && isReset">
      <div class="video-cover"></div>
       <video
          class="videostyle1"
          id="webcam"
          style="object-fit: fill"
          width="100%"
          height="100%"
          playsinline
          webkit-playsinline="webkit-playsinline"
          preload='auto'
        >
          <source :src="videoSrc"/>
        </video>
    </div>
    <div 
      class="base-img" v-if="!isReset">
      <img :src="poster" alt="">
    </div>

    <div :class="device=='ios'? 'rotateParent' : ''" >
      <canvas
        id="canvas"
        class="canvas1"
        style="margin: 0; padding: 0"
      ></canvas>
    </div>

    <!-- 关闭按钮 -->
    <mt-button class="video-close" @click.native="closeVideo" v-if="isReset">×</mt-button>

  
  </div>
</template>

<script>
import { uploadFile, deleteMulti } from "@/utils/oss.js";
import { Toast, MessageBox  } from "mint-ui";
import { reqVerifyFace} from "@/api/verify";
import {mapState} from 'vuex'
import { setToken} from '@/utils/auth'
export default {
  data() {
    return {
      videoWidth: "",
      videoHeight: "",
      video:'',//视频元素
      poster:require('@/assets/images/cover.jpg'), //海报
      videoSrc: '',//require('@/assets/video.mp4'), //视频src
      imginfo: "", // 识别成功的canvas base64 
      imgList: [], // 废弃的图片数组
      count:5, // 识别成功的时间 
      isAlreadyRecord: false, // 识别成功的标志
      isVerify:false,//是否认证成功    
      isUpload:false,//是否录入到人脸库 
      disable:false,
      isReset:false,//是否重置
      isPlay:false,//是否播放

    };
  },
   computed:{
    ...mapState(['userInfo','groupId','device'])
  },
  watch: {
    async count(val) {
      if(val == 0) {
        // 当倒计时结束，停掉视频，显示图片
        this.isAlreadyRecord = true;
        this.stopCapture();

        // 如果是倒计时结束，且没有识别成功，弹起alert，清除图片
        if(!this.isVerify) {
            // 超过识别时间
            MessageBox.alert('识别失败').then(() => {
              this.$router.go(-1);
            })    
        }
      }else {
        this.captureAvg()
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      // 一秒后执行视频
      setTimeout(() => {
       this.isResetFace()
      }, 500);   
    });
  },
  destroyed(){
    var video = document.getElementById("webcam");
    if(video){
      video.pause()
    }
  },
  methods: {
    // 重新录入
    isResetFace(){
      this.isReset = true;
      setTimeout(() => {
        
      this.moveToCameraAVG(() => {
        this.isPlay = true
        this.captureAvg()
      }); 
      // this.openVideo(() => {
      //   this.isPlay = true
      //     this.captureAvg()
      //   })
      }, 200);
    },
    // 关闭退出
    closeVideo() {
      if(!this.isAlreadyRecord){
        this.stopCapture()
      }
      this.$router.go(-1);
    },
    //停止摄像机
    stopCapture() {
      var video = document.getElementById("webcam");
      try {
        let stream = video.srcObject;
        let tracks = stream.getTracks();
        tracks.forEach((track) => {
          track.stop();
        });
      } catch (error) {
        video.pause()
      }
    },
    // 播放视频
     openVideo(callback) {
      let video = document.getElementById("webcam");
      // this.initVideo()
      video.play();
      callback()
    },
    // 获取摄像头
    moveToCameraAVG(callback) {
      var self = this;
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {};
      }
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function (constraints) {
          var getUserMedia =
            navigator.getUserMedia ||
            navigator.webkitGetUserMedia ||
            navigator.mozGetUserMedia ||
            navigator.msGetUserMedia ||
            navigator.oGetUserMedia;
          if (!getUserMedia) {
            return Promise.reject(
              new Error("getUserMedia is not implemented in this browser")
            );
          }
          return new Promise(function (resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject);
          });
        };
      }
      if (window.stream) {
        window.stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
      var constraints = (window.constraints = {
        audio: false,
        video: {
          sourceId: "default",
          width: 1920,
          height: 1080,
          facingMode: { exact: "user" },
        },
      });
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(function (stream) {
          var video = document.getElementById("webcam");
          try {
            window.stream = stream;
            video.srcObject = stream;
          } catch (error) {
            video.src = window.URL.createObjectURL(stream);
          }
          self.localMediaStream = stream;
          video.play();

          Toast("开始识别，请正对手机");
          setTimeout(() => {
            callback()
          }, 500);
        })
        .catch(function (err) {
          alert(err.name + ": " + err.message);
          self.$router.go(-1);
        });
    },
    // 照片拍照
    async captureAvg() {
      var vm = this;
      var video = document.getElementById("webcam");
      var canvas = document.getElementById("canvas"),
      ctx = canvas.getContext("2d"),
      CHeight = video.clientHeight, //获取屏幕大小让canvas自适应
      CWidth = video.clientWidth;
      canvas.width = CWidth;
      canvas.height = CHeight;
      canvas.setAttribute('style','margin: 0; padding: 0; transform: rotateY(180deg);-webkit-transform: rotateY(180deg);-moz-transform: rotateY(180deg);')
      ctx.drawImage(video, 0, 0, CWidth, CHeight);
      var dataURL = canvas.toDataURL("image/jpeg");
      vm.imginfo = dataURL;

      let imgfile = this.dataURLtoFile(dataURL, "verify-face-image");
      uploadFile('sign',imgfile).then(res => {
          this.imgList.push(res);

          // 跟据截图，对接识别的接口
          // 识别接口
          this.startVerifyFace(res)
            .then(async (res) => {
              // 停掉倒计时
              // 删除之前的废弃图片，留下最后一张
              // 识别成功后，清除废弃图片
              this.count = 0;
              this.isVerify = true; 
              let abandonList = this.imgList.splice(0, this.imgList.length - 1);

              await deleteMulti(abandonList);
              
              // 识别成功,拉取用户信息,存起来
              let {userInfo,token} = res.tokenEntity
              // userInfo
              userInfo = userInfo[0];

              setToken(token)
              this.$store.commit('SET_TOKEN',token)
              window.localStorage.setItem('userInfo', JSON.stringify(userInfo))
              
              MessageBox.alert('识别成功: ' + userInfo.userName).then(() => {
                this.$router.go(-1);
              })

            })
            .catch(async () => {
              //  倒计时减一
               this.count--;
              if(this.count==0) {
                this.isVerify = false;
                await deleteMulti(this.imgList);
              }
        });
       })
    },
    // 将base64转换为文件
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {
        type: mime,
      });
    },
    // 人脸识别的接口
    startVerifyFace(img) {
      return new Promise((resolve, reject) => {
        reqVerifyFace({
          image: img,
          groupId:this.groupId,
        }).then((res) => {
          if (res.code == 0) {
            resolve(res);
          } else {
            console.log(res)
            reject(res);
          }
        }).catch(async() => {
          // 接口报错，直接退出
           Toast('接口报错');
          await deleteMulti(this.imgList);
           this.closeVideo()
        })
      });
    },
    //删除废弃图片
    deleteImg(arr) {
      deleteMulti(arr)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
   
  },
};
</script>

<style lang="less">
.video-close {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 99999;
  transform: translateZ(10000px); /*这里是给safari用的*/
  width: 30px;
  height: 30px;
  line-height: 30px;
  padding: 0;
  text-align: center;
  color: #000;

  font-size: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px #fff;
  border-radius: 100%;
  z-index: 100;
}
.video {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  transform: translateZ(10px); /*这里是给safari用的*/
  width: 100%;
  height: 100%;
  background-color: #000;
 
    
}


.video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.video-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(~@/assets/images/cover.jpg) center center no-repeat;
  object-fit: cover;
  background-size: 100% 100%;
  z-index: 999;
  transform: translateZ(1000px); /*这里是给safari用的*/
  opacity: 0.2;
}

/*前置摄像头镜面反转*/
.rotateParent{
   perspective:1000;
}
.videostyle1{
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);    /* Safari 和 Chrome */
  -moz-transform: rotateY(180deg);
}
.canvas1{
   transform: rotateY(180deg);
   -webkit-transform: rotateY(180deg);    /* Safari 和 Chrome */
    -moz-transform: rotateY(180deg);
}
.confirm-btn{
  position: fixed;
  bottom: .266667rem;
  left: 0;
  right: 0;
  z-index: 99999;
  transform: translateZ(10000px); /*这里是给safari用的*/
  width: 100vw;
  height: 1.333333rem;
  display: flex;
  align-items: center;
  padding: 0 .4rem;
  box-sizing: border-box;
  justify-content: space-between;
  .mint-button-text{
    font-size: 14px;
  }
}
.loading-container{
  position: fixed;
  top: 40vh;
  left: 50%;
  transform: translate3d(-50%,0,10000px);
  z-index: 999999;

}
.base-img{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  transform: translateZ(1000px); /*这里是给safari用的*/
  img{
     width: 100%;
     height: 100%;
     object-fit: cover;
     transform: rotateY(180deg);
     -webkit-transform: rotateY(180deg);    /* Safari 和 Chrome */
    -moz-transform: rotateY(180deg);
  }
}
</style>